import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { User } from "oidc-client";

export interface IAuthState {
  user: User
}
@Module({ dynamic: true, store, name: 'auth' })
class Auth extends VuexModule {

  authUser: User | undefined

  get getAuthUser() {
    return this.authUser;
  }

  @Mutation
  private SET_AUTHUSER(payload: User) {
    this.authUser = payload;
  }

  @Action
  public Set_AuthUser(payload: User) {
    this.SET_AUTHUSER(payload)
  }
}
export const AuthModule = getModule(Auth)
