
import { Component, Prop, Vue } from "vue-property-decorator";
import { AppModule } from "@/store/modules/app";
import SidebarItem from "./SidebarItem.vue";
import variables from "@/styles/_variables.scss";
import { UserModule } from "../../../store/modules/user";
import { PermissionModule } from "@/store/modules/permission";
import Logo from "./logo.vue";

@Component({
  name: "SideBar",
  components: {
    SidebarItem,
    Logo
  }
})
export default class extends Vue {
  get sidebar() {
    return AppModule.sidebar;
  }

  get menus() {
    let menus = PermissionModule.menus;
    return menus;
  }

  get variables() {
    return variables;
  }

  get isCollapse() {
    return !this.sidebar.opened;
  }
}
