
import {Component, Vue} from "vue-property-decorator";
import {AppModule} from "@/store/modules/app";
import {UserModule} from "@/store/modules/user";
import Breadcrumb from "@/components/Breadcrumb/index.vue";
import Hamburger from "@/components/Hamburger/index.vue";

import {auth} from "@/services/index";
import {removeOu} from "../../../utils/cookies";
import ChangePassword from "@/views/ou/user/changePassword.vue";

@Component({
  name: "Navbar",
  components: {
    Breadcrumb,
    Hamburger,
    ChangePassword
  }
})
export default class extends Vue {
  get sidebar() {
    return AppModule.sidebar;
  }

  get device() {
    return AppModule.device.toString();
  }

  get avatar() {
    return UserModule.getAvatar;
  }

  get userName() {
    return UserModule.getFullName;
  }

  private toggleSideBar() {
    AppModule.ToggleSideBar(false);
  }

  private async logout() {
    // await UserModule.LogOut()
    removeOu();
    UserModule.Logout();
    // await auth.logout();
    setTimeout(()=>{
      this.$router.push(`/account/login`)
    },500)

  }

  defineImg = require('@/assets/images/default-header.png')

  private async changePassword() {
    (this.$refs.changePasswordForm as any).show = true;
  }

  logoutLimit = 1000 * 60 * 60 * 2;
  logoutTimer = setTimeout(this.logout, this.logoutLimit)

  userOpDelay() {
    clearTimeout(this.logoutTimer);
    this.logoutTimer = setTimeout(this.logout, this.logoutLimit)
  }

  mounted(){
    document.getElementById("app")!.addEventListener('keydown',()=>{
      this.userOpDelay()
    })
    document.getElementById("app")!.addEventListener('mousemove',()=>{
      this.userOpDelay()
    })
    document.getElementById("app")!.addEventListener('mousedown',()=>{
      this.userOpDelay()
    })
    document.getElementById("app")!.addEventListener('click',()=>{
      this.userOpDelay()
    })
    document.getElementById("app")!.addEventListener('scroll',()=>{
      this.userOpDelay()
    })
  }

}
