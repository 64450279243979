import request from '../../utils/request'

export default {
  getAll: data => request({
    url: '/api/services/app/DataDictionaries/GetAll',
    method: 'get',
    params: data
  }),
  get: data => request({
    url: '/api/services/app/DataDictionaries/Get',
    method: 'get',
    params: data
  }),
  getForEdit: data => request({
    url: '/api/services/app/DataDictionaries/GetForEdit',
    method: 'get',
    params: data
  }),
  create: data => request({
    url: '/api/services/app/DataDictionaries/Create',
    method: 'post',
    data: data
  }),
  update: data => request({
    url: '/api/services/app/DataDictionaries/Update',
    method: 'put',
    data: data
  }),
  delete: function (data) {
    return request({
      url: '/api/services/app/DataDictionaries/delete',
      method: 'delete',
      params: data
    })
  },
  getListByParentId: data => request({
    url: '/api/services/app/DataDictionaries/GetListByParentId',
    method: 'get',
    params: data
  }),
}
