import {
  RouteConfig
} from 'vue-router'
import Layout from '@/layout/index.vue'

const cmsRouter: RouteConfig[] = [
  {
    path: '/cms',
    component: Layout,
    name: 'cms',
    redirect: '/cms/category',
    meta: {
      title: '内容管理'
    },
    children: [
      {
        path: 'categories',
        name: 'categories',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/cms/category/index.vue'),
        meta: {
          title: '栏目管理'
        }
      },
      {
        path: 'category-create/:parentId',
        name: 'category-create',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/cms/category/edit.vue'),
        meta: {
          title: '新建栏目'
        }
      },
      {
        path: 'category-edit/:id',
        name: 'category-edit',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/cms/category/edit.vue'),
        meta: {
          title: '编辑栏目'
        }
      },
      {
        path: 'content-list',
        name: 'content-list',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/cms/content/list.vue'),
        meta: {
          title: '文章管理'
        }
      },
      {
        path: 'content-create',
        name: 'content-create',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/cms/content/edit.vue'),
        meta: {
          title: '新增文章'
        }
      },
      {
        path: 'content-edit/:id',
        name: 'content-edit',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/cms/content/edit.vue'),
        meta: {
          title: '編輯文章'
        }
      },
      {
        path: 'content-detail/:id',
        name: 'content-detail',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/cms/content/detail.vue'),
        meta: {
          title: '文章详情'
        }
      },
      {
        path: 'trash',
        name: 'trash',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/cms/content/trash.vue'),
        meta: {
          title: '文章废纸篓'
        }
      },
      {
        path: 'friendLink',
        name: 'friendLink',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/cms/friendLink/list.vue'),
        meta: {
          title: '合作机构'
        }
      },
      {
        path: 'friendLink/create',
        name: 'friendLinkCreate',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/cms/friendLink/edit.vue'),
        meta: {
          title: '新建合作机构'
        }
      },
      {
        path: 'friendLink/edit/:id',
        name: 'friendLinkEdit',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/cms/friendLink/edit.vue'),
        meta: {
          title: '编辑合作机构'
        }
      },
      {
        path: 'album-list',
        name: 'album-list',
        component: () => import(/* webpackChunkName: "albumPhotosList" */ '@/views/cms/albumPhotos/list.vue'),
        meta: {
          title: '相册管理'
        }
      },
      {
        path: 'photo-list/:id',
        name: 'photo-list',
        component: () => import(/* webpackChunkName: "photoList" */ '@/views/cms/albumPhotos/photoList.vue'),
        meta: {
          title: '照片管理'
        }

      },
      {
        path: 'anchor',
        name: 'anchor',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/cms/anchor/list.vue'),
        meta: {
          title: '锚点管理'
        }
      },
      {
        path: 'anchor/create',
        name: 'anchorCreate',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/cms/anchor/edit.vue'),
        meta: {
          title: '新建锚点'
        }
      },
      {
        path: 'anchor/edit/:id',
        name: 'anchorEdit',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/cms/anchor/edit.vue'),
        meta: {
          title: '编辑锚点'
        }
      },
      {
        path: 'swiper',
        name: 'swiper',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/cms/swiper/list.vue'),
        meta: {
          title: '轮播图管理'
        }
      },
      {
        path: 'swiper/create',
        name: 'swiperCreate',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/cms/swiper/edit.vue'),
        meta: {
          title: '新建轮播图'
        }
      },
      {
        path: 'swiper/edit/:id',
        name: 'swiperEdit',
        component: () => import(/* webpackChunkName: "cms" */ '@/views/cms/swiper/edit.vue'),
        meta: {
          title: '编辑轮播图'
        }
      },
    ]
  }
]


export default cmsRouter;
