import {
  RouteConfig
} from 'vue-router'
import Layout from '@/layout/index.vue'

const ouRouter: RouteConfig[] = [
  {
    path: '/ou',
    component: Layout,
    name: 'admin',
    redirect: '/ou/departments',
    meta: {
      title: '组织管理'
    },
    children: [
      {
        path: 'departments',
        name: 'departments',
        component: () => import(/* webpackChunkName: "ou" */ '@/views/ou/department/index.vue'),
        meta: {
          title: '部门管理'
        }
      },
      {
        path: 'users',
        name: 'usersList',
        component: () => import(/* webpackChunkName: "ou" */ '@/views/ou/user/list.vue'),
        meta: {
          title: '用户管理'
        }
      },
      {
        // 合作方的用户管理
        path: 'ou-users',
        name: 'ou-users',
        component: () => import(/* webpackChunkName: "ou" */ '@/views/ou/user/ouUsers.vue'),
        meta: {
          title: '用户管理'
        }
      },
      {
        path: 'users/create',
        name: 'usersCreate',
        component: () => import(/* webpackChunkName: "ou" */ '@/views/ou/user/edit.vue'),
        meta: {
          title: '新建用户'
        }
      },
      {
        path: 'users/edit/:id',
        name: 'usersEdit',
        component: () => import(/* webpackChunkName: "ou" */ '@/views/ou/user/edit.vue'),
        meta: {
          title: '修改用户'
        }
      },
      {
        path: 'users/detail/:id',
        name: 'usersDetail',
        component: () => import(/* webpackChunkName: "ou" */ '@/views/ou/user/detail.vue'),
        meta: {
          title: '用户详情'
        }
      },
    ]
  }
]


export default ouRouter;
