import request from '../../utils/request'

export default {
  isTenantAvailable: data => request({
    url: '/api/services/app/Account/IsTenantAvailable',
    method: 'post',
    data: data
  }),
  register: data => request({
    url: '/api/services/app/Account/Register',
    method: 'post',
    data: data
  })

}
