import {
  RouteConfig
} from 'vue-router'
import Layout from '@/layout/index.vue'

const financeRouter: RouteConfig[] = [
  {
    path: '/finance',
    name: 'finance',
    redirect: '/finance',
    component: Layout,
    meta: {
      title: '财务管理'
    },
    children: [
      {
        path: 'expenseRecord',
        name: 'expenseRecord',
        component: () => import(/* webpackChunkName: "partnerInvite" */ '@/views/finance/expenseRecord/index.vue'),
        meta: {
          title: '支出记录管理'
        },

      },
      {
        path: 'expenseRecord/create',
        name: 'expenseRecordCreate',
        component: () => import(/* webpackChunkName: "contentList" */ '@/views/finance/expenseRecord/edit.vue'),
        meta: {
          title: '新增支出记录'
        }
      },
      {
        path: 'expenseRecord/edit/:id',
        name: 'expenseRecordEdit',
        component: () => import(/* webpackChunkName: "partnerInvite" */ '@/views/finance/expenseRecord/edit.vue'),
        meta: {
          title: '编辑支出记录'
        }
      },
      {
        path: 'expenseRecord/detail/:id',
        name: 'expenseRecordDetail',
        component: () => import(/* webpackChunkName: "partnerInvite" */ '@/views/finance/expenseRecord/detail.vue'),
        meta: {
          title: '支出记录详情'
        }
      },
      {
        path: 'expenseRecord/change/:id',
        name: 'expenseRecordChange',
        component: () => import(/* webpackChunkName: "partnerInvite" */ '@/views/finance/expenseRecord/changeApply.vue'),
        meta: {
          title: '支出记录变更'
        }
      },
      {
        path: 'expenseRecord/changeRecord/:id',
        name: 'expenseRecordChangeRecord',
        component: () => import(/* webpackChunkName: "partnerInvite" */ '@/views/finance/expenseRecord/changeRecord.vue'),
        meta: {
          title: '支出记录变更历史记录'
        }
      },
      {
        path: 'bankReconciliation',
        name: 'bankReconciliation',
        component: () => import(/* webpackChunkName: "partnerInvite" */ '@/views/finance/bankReconciliation/index.vue'),
        meta: {
          title: '银行对账'
        }
      },
      {
        path: 'financeManage',
        name: 'financeManageProjectReport',
        component: () => import(/* webpackChunkName: "partnerInvite" */ '@/views/finance/financeManage/projectReport.vue'),
        meta: {
          title: '项目报表总计'
        }
      },
      {
        path: 'financeManage/projectDetailReport/:id',
        name: 'financeManageProjectDetailReport',
        component: () => import(/* webpackChunkName: "partnerInvite" */ '@/views/finance/financeManage/projectDetailReport.vue'),
        meta: {
          title: '项目报表明细'
        }
      }
      // {
      //   path: 'import/result/:reportName',
      //   name: 'uploadTemplateDetail',
      //   component: () => import('@/components/UploadTemplate/result.vue'),
      //   meta: {
      //     title: '模板导入结果'
      //   }
      // }
    ]
  }
]


export default financeRouter;
