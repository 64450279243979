import request from '../../utils/request'

export default {
  getForEdit: data => request({
    url: '/api/services/app/Activity/GetForEdit',
    method: 'get',
    params: data
  }),
  getAll: data => request({
    url: '/api/services/app/Activity/GetAll',
    method: 'get',
    params: data
  }),
  get: data => request({
    url: '/api/services/app/Activity/Get',
    method: 'get',
    params: data
  }),
  create: data => request({
    url: '/api/services/app/Activity/Create',
    method: 'post',
    data: data
  }),
  update: data => request({
    url: '/api/services/app/Activity/Update',
    method: 'put',
    data: data
  }),

  'delete': function(data) {
    return request({
      url: '/api/services/app/Activity/delete',
      method: 'delete',
      params: data
    })
  },
  getQr: function(data) {
    return request({
      url: '/api/services/app/Activity/GetQr',
      method: 'get',
      params: data
    })
  },
  changeStatus: data => request({
    url: '/api/services/app/Activity/changeStatus',
    method: 'get',
    params: data
  }),
  approveActivity: data => request({
    url: '/api/services/app/Activity/approveActivity',
    method: 'post',
    data: data
  })
}
