import {
  RouteConfig
} from 'vue-router'
import Layout from '@/layout/index.vue'

const workbenchRouter: RouteConfig[] = [
  {
    path: '/workbench',
    component: Layout,
    name: 'workbench',
    meta: {
      title: 'OA审批管理'
    },
    children: [
      {
        path: 'index',
        name: 'workbench-index',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/index.vue'),
        meta: {
          title: 'OA审批管理'
        }
      },
      {
        path: 'matter-audit-create',
        name: 'matter-audit-create',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/matterAudit/edit.vue'),
        meta: {
          title: '新增基金会事项审批单'
        }
      },
      {
        path: 'matter-audit-edit/:id',
        name: 'matter-audit-edit',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/matterAudit/edit.vue'),
        meta: {
          title: '编辑基金会事项审批单'
        }
      },
      {
        path: 'matter-audit-detail/:id',
        name: 'matter-audit-detail',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/matterAudit/detail.vue'),
        meta: {
          title: '基金会事项审批单详情'
        }
      },
      //
      {
        path: 'privateCarPublicSubsidy',
        name: 'privateCarPublicSubsidy-index',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/privateCarPublicSubsidy/home.vue'),
        meta: {
          title: '私车公用申请'
        }
      },
      {
        path: 'privateCarPublicSubsidy-create',
        name: 'privateCarPublicSubsidy-create',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/privateCarPublicSubsidy/edit.vue'),
        meta: {
          title: '新增私车公用申请'
        }
      },
      {
        path: 'privateCarPublicSubsidy-edit/:id',
        name: 'privateCarPublicSubsidy-edit',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/privateCarPublicSubsidy/edit.vue'),
        meta: {
          title: '编辑私车公用申请'
        }
      },
      {
        path: 'privateCarPublicSubsidy-detail/:id',
        name: 'privateCarPublicSubsidy-detail',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/privateCarPublicSubsidy/detail.vue'),
        meta: {
          title: '私车公用申请详情'
        }
      },
      //用车申请
      //
      {
        path: 'vehicleUsageApplication',
        name: 'vehicleUsageApplication-index',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/vehicleUsageApplication/home.vue'),
        meta: {
          title: '车辆使用申请'
        }
      },
      {
        path: 'vehicleUsageApplication-create',
        name: 'vehicleUsageApplication-create',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/vehicleUsageApplication/edit.vue'),
        meta: {
          title: '新增车辆使用申请'
        }
      },
      {
        path: 'vehicleUsageApplication-edit/:id',
        name: 'vehicleUsageApplication-edit',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/vehicleUsageApplication/edit.vue'),
        meta: {
          title: '编辑车辆使用申请'
        }
      },
      {
        path: 'vehicleUsageApplication-detail/:id',
        name: 'vehicleUsageApplication-detail',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/vehicleUsageApplication/detail.vue'),
        meta: {
          title: '车辆使用申请详情'
        }
      },
      //TravelApprovalForm
      //出差申请
      {
        path: 'travelApproval',
        name: 'travelApproval-index',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/travelApproval/home.vue'),
        meta: {
          title: '外出出差申请'
        }
      },
      {
        path: 'travelApproval-create',
        name: 'travelApproval-create',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/travelApproval/edit.vue'),
        meta: {
          title: '新增外出出差申请'
        }
      },
      {
        path: 'travelApproval-edit/:id',
        name: 'travelApproval-edit',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/travelApproval/edit.vue'),
        meta: {
          title: '编辑外出出差申请'
        }
      },
      {
        path: 'travelApproval-detail/:id',
        name: 'travelApproval-detail',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/travelApproval/detail.vue'),
        meta: {
          title: '外出出差申请详情'
        }
      },
      //ItemBorrowingForm
      //物品借用
      {
        path: 'itemBorrowing',
        name: 'itemBorrowing-index',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/itemBorrowing/home.vue'),
        meta: {
          title: '物品借用申请'
        }
      },
      {
        path: 'itemBorrowing-create',
        name: 'itemBorrowing-create',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/itemBorrowing/edit.vue'),
        meta: {
          title: '新增物品借用申请'
        }
      },
      {
        path: 'itemBorrowing-edit/:id',
        name: 'itemBorrowing-edit',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/itemBorrowing/edit.vue'),
        meta: {
          title: '编辑物品借用申请'
        }
      },
      {
        path: 'itemBorrowing-detail/:id',
        name: 'itemBorrowing-detail',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/itemBorrowing/detail.vue'),
        meta: {
          title: '物品借用申请详情'
        }
      },
      //InventoryInForm
      //物资入库
      {
        path: 'inventoryIn',
        name: 'inventoryIn-index',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/inventoryIn/home.vue'),
        meta: {
          title: '物资入库申请'
        }
      },
      {
        path: 'inventoryIn-create',
        name: 'inventoryIn-create',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/inventoryIn/edit.vue'),
        meta: {
          title: '新增物资入库'
        }
      },
      {
        path: 'inventoryIn-edit/:id',
        name: 'inventoryIn-edit',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/inventoryIn/edit.vue'),
        meta: {
          title: '编辑物资入库'
        }
      },
      {
        path: 'inventoryIn-detail/:id',
        name: 'inventoryIn-detail',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/inventoryIn/detail.vue'),
        meta: {
          title: '物资入库详情'
        }
      },
      //InventoryOutForm
      //物资入库
      {
        path: 'inventoryOut',
        name: 'inventoryOut-index',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/inventoryOut/home.vue'),
        meta: {
          title: '物资出库申请'
        }
      },
      {
        path: 'inventoryOut-create',
        name: 'inventoryOut-create',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/inventoryOut/edit.vue'),
        meta: {
          title: '新增物资出库'
        }
      },
      {
        path: 'inventoryOut-edit/:id',
        name: 'inventoryOut-edit',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/inventoryOut/edit.vue'),
        meta: {
          title: '编辑物资出库'
        }
      },
      {
        path: 'inventoryOut-detail/:id',
        name: 'inventoryOut-detail',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/workbench/inventoryOut/detail.vue'),
        meta: {
          title: '物资出库详情'
        }
      }

    ]
  }
]


export default workbenchRouter;
