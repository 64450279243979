import {
  RouteConfig
} from 'vue-router'
import Layout from '@/layout/index.vue'

const adminRouter: RouteConfig[] = [
  {
    path: '/admin',
    component: Layout,
    name: 'admin',
    redirect: '/admin/role',
    meta: {
      title: '系统管理'
    },
    children: [
      {
        path: 'permissionDefinition',
        name: 'permissionDefinition',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/permission/definition/index.vue'),
        meta: {
          title: '权限定义'
        }
      },
      {
        path: 'roles',
        name: 'roles',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/role/list.vue'),
        meta: {
          title: '角色管理'
        }
      },
      {
        path: 'dataDictionaries',
        name: 'dataDictionaries',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/dataDictionaries/list.vue'),
        meta: {
          title: '数据字典'
        }
      },
    ]
  }
]

export default adminRouter;
