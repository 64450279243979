import request from '../../utils/request'

export default {
  // getForEdit: data => request({
  //   url: '/api/services/app/Comment/GetForEdit',
  //   method: 'get',
  //   params: data
  // }),
  getAll: data => request({
    url: '/api/services/app/Comment/GetAll',
    method: 'get',
    params: data
  }),
  get: data => request({
    url: '/api/services/app/Comment/Get',
    method: 'get',
    params: data
  }),
  create: data => request({
    url: '/api/services/app/Comment/Create',
    method: 'post',
    data: data
  }),

  update: data => request({
    url: '/api/services/app/Comment/Update',
    method: 'put',
    data: data
  }),
  'delete': function(data) {
    return request({
      url: '/api/services/app/Comment/delete',
      method: 'delete',
      params: data
    })
  },
  reply: data => request({
    url: '/api/services/app/Comment/Reply',
    method: 'post',
    data: data
  })
}
