
import { Component, Prop, Vue } from "vue-property-decorator";
import { isExternal } from "@/utils/validate";
import Cookies from "js-cookie";

@Component({
  name: "SidebarItemLink"
})
export default class extends Vue {
  @Prop({ required: true }) private to!: string;

  private isExternal = isExternal;

  jumpLink(){
    //清空分页组件分页数据缓存
    let current=this.$route.path;

    if(this.to&&!(this.to.indexOf(current) > -1)){
      Cookies.set('PageInfoCache','')
    }
    if(isExternal(this.to)){
      window.open(this.to,'_blank')
    }
    else{
      this.$router.push(this.to);
    }
  }
}
