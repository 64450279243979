const areaAllApis: any = {}

const requiredApis = require.context(
  '@/api/areas', true, /\.js$/
)

requiredApis.keys()
  .forEach((fileName: string) => {
    const name: any[] | null = /([\w\d]+)/.exec(fileName)
    const areaApi = requiredApis(fileName)
    if (name != null)
      areaAllApis[name[0]] = (areaApi.default)
  });
export default areaAllApis
