import request from '../../utils/request'

export default {
  getForEdit: data => request({
    url: '/api/services/app/ActivityApply/GetForEdit',
    method: 'get',
    params: data
  }),
  getAll: data => request({
    url: '/api/services/app/ActivityApply/GetAll',
    method: 'get',
    params: data
  }),
  get: data => request({
    url: '/api/services/app/ActivityApply/Get',
    method: 'get',
    params: data
  }),
  create: data => request({
    url: '/api/services/app/ActivityApply/Create',
    method: 'post',
    data: data
  }),
  update: data => request({
    url: '/api/services/app/ActivityApply/Update',
    method: 'put',
    data: data
  }),
  delete: data => {
    return request({
      url: '/api/services/app/ActivityApply/delete',
      method: 'delete',
      params: data
    })
  }
}
