import request from '../../utils/request'

export default {
  getAll: data => request({
    url: '/api/services/app/Tenant/GetAll',
    method: 'get',
    params: data
  }),
  get: data => request({
    url: '/api/services/app/Tenant/Get',
    method: 'get',
    params: data
  }),
  create: data => request({
    url: '/api/services/app/Tenant/Create',
    method: 'post',
    data: data
  }),
  update: data => request({
    url: '/api/services/app/Tenant/Update',
    method: 'put',
    data: data
  }),
  'delete': function(data) {
    return request({
      url: '/api/services/app/Tenant/delete',
      method: 'delete',
      params: data
    })
  }
}
