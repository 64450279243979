import {
  RouteConfig
} from 'vue-router'
import Layout from '@/layout/index.vue'

const projectRouter: RouteConfig[] = [
  {
    path: '/project',
    component: Layout,
    name: 'project',
    redirect: '/project',
    meta: {
      title: '项目管理'
    },
    children: [
      {
        path: 'externalProjectChangeApply',
        name: 'externalProjectChangeApply',
        component: () => import(/* webpackChunkName: "external-project" */ '@/views/project/externalProjectChangeApply/list.vue'),
        meta: {
          title: '外部项目变更申请'
        }
      },
      {
        path: 'externalProjectChangeApply/create',
        name: 'externalProjectChangeApplyCreate',
        component: () => import(/* webpackChunkName: "external-project" */ '@/views/project/externalProjectChangeApply/edit.vue'),
        meta: {
          title: '新建外部项目变更申请'
        }
      },
      {
        path: 'externalProjectChangeApply/edit/:id',
        name: 'externalProjectChangeApplyEdit',
        component: () => import(/* webpackChunkName: "external-project" */ '@/views/project/externalProjectChangeApply/edit.vue'),
        meta: {
          title: '编辑外部项目变更申请'
        }
      },
      {
        path: 'externalProjectChangeApply/detail/:id',
        name: 'externalProjectChangeApplyDetail',
        component: () => import(/* webpackChunkName: "external-project" */ '@/views/project/externalProjectChangeApply/detail.vue'),
        meta: {
          title: '外部项目变更申请详情'
        }
      },
      {
        path: 'externalProjectChangeApply/editProject/:targetExternalProjectId',
        name: 'externalProjectChangeApplyEditProject',
        component: () => import(/* webpackChunkName: "external-project" */ '@/views/project/externalProject/proposal/edit.vue'),
        meta: {
          title: '编辑外部项目变更申请项目书'
        }
      },
      {
        path: 'externalProjectChangeApply/createProject/:sourceProjectId',
        name: 'externalProjectChangeApplyCreateProject',
        component: () => import(/* webpackChunkName: "external-project" */ '@/views/project/externalProject/proposal/edit.vue'),
        meta: {
          title: '新建外部项目变更申请项目书'
        }
      },
      {
        path: 'projectSeries',
        name: 'projectSeries',
        component: () => import(/* webpackChunkName: "projectSeries" */ '@/views/project/projectSeries/list.vue'),
        meta: {
          title: '项目系列管理'
        }
      },
      {
        path: 'projectSeries/create',
        name: 'projectSeriesCreate',
        component: () => import(/* webpackChunkName: "projectSeries" */ '@/views/project/projectSeries/edit.vue'),
        meta: {
          title: '新建项目系列'
        }
      },
      {
        path: 'projectSeries/edit/:id',
        name: 'projectSeriesEdit',
        component: () => import(/* webpackChunkName: "projectSeries" */ '@/views/project/projectSeries/edit.vue'),
        meta: {
          title: '编辑项目系列'
        }
      },
      {
        path: 'otherIncome',
        name: 'otherIncome',
        component: () => import(/* webpackChunkName: "otherIncome" */ '@/views/project/otherIncome/list.vue'),
        meta: {
          title: '其他资金收入管理'
        }
      },
      {
        path: 'otherIncome/create',
        name: 'otherIncomeCreate',
        component: () => import(/* webpackChunkName: "otherIncome" */ '@/views/project/otherIncome/edit.vue'),
        meta: {
          title: '新建记录'
        }
      },
      {
        path: 'otherIncome/edit/:id',
        name: 'otherIncomeEdit',
        component: () => import(/* webpackChunkName: "otherIncome" */ '@/views/project/otherIncome/edit.vue'),
        meta: {
          title: '编辑记录'
        }
      },
      {
        path: 'otherIncome/detail',
        name: 'otherIncomeDetail',
        component: () => import(/* webpackChunkName: "otherIncome" */ '@/views/project/otherIncome/detail.vue'),
        meta: {
          title: '其他资金收入详情'
        }
      },
      {
        path: 'donationIncome',
        name: 'donationIncome',
        component: () => import(/* webpackChunkName: "donationIncome" */ '@/views/project/donationIncome/list.vue'),
        meta: {
          title: '资金捐赠管理'
        }
      },
      {
        path: 'donationIncome-create',
        name: 'donationIncome-create',
        component: () => import(/* webpackChunkName: "donationIncome" */ '@/views/project/donationIncome/edit.vue'),
        meta: {
          title: '新建记录'
        }
      },
      {
        path: 'donationIncome-edit/:id',
        name: 'donationIncome-edit',
        component: () => import(/* webpackChunkName: "donationIncome" */ '@/views/project/donationIncome/edit.vue'),
        meta: {
          title: '编辑记录'
        }
      },
      {
        path: 'donationIncome-detail',
        name: 'donationIncome-detail',
        component: () => import(/* webpackChunkName: "donationIncome" */ '@/views/project/donationIncome/detail.vue'),
        meta: {
          title: '捐赠详情'
        }
      },
      //外部项目月计划
      {
        path: 'externalMonthPlan',
        name: 'externalMonthPlan',
        component: () => import(/* webpackChunkName: "externalMonthPlan" */ '@/views/project/externalMonthPlan/list.vue'),
        meta: {
          title: '项目月计划表'
        }
      },
      {
        path: 'externalMonthPlan/create',
        name: 'externalMonthPlanCreate',
        component: () => import(/* webpackChunkName: "externalMonthPlan" */ '@/views/project/externalMonthPlan/edit.vue'),
        meta: {
          title: '新建外部项目月计划'
        }
      },
      {
        path: 'externalMonthPlan/edit/:id',
        name: 'externalMonthPlanEdit',
        component: () => import(/* webpackChunkName: "externalMonthPlan" */ '@/views/project/externalMonthPlan/edit.vue'),
        meta: {
          title: '编辑外部项目月计划'
        }
      },
      {
        path: 'externalMonthPlan/detail/:id',
        name: 'externalMonthPlanDetail',
        component: () => import(/* webpackChunkName: "externalMonthPlan" */ '@/views/project/externalMonthPlan/detail.vue'),
        meta: {
          title: '外部项目月计划表详情'
        }
      },
      //个人年终总结
      {
        path: 'internalPersonalYearEndSummary',
        name: 'internalPersonalYearEndSummary',
        component: () => import(/* webpackChunkName: "internalPersonalYearEndSummary" */ '@/views/project/internalPersonalYearEndSummary/list.vue'),
        meta: {
          title: '个人年终总结'
        }
      },
      {
        path: 'internalPersonalYearEndSummary/create',
        name: 'internalPersonalYearEndSummaryCreate',
        component: () => import(/* webpackChunkName: "internalPersonalYearEndSummary" */ '@/views/project/internalPersonalYearEndSummary/edit.vue'),
        meta: {
          title: '新建个人年终总结'
        }
      },
      {
        path: 'internalPersonalYearEndSummary/edit/:id',
        name: 'internalPersonalYearEndSummaryEdit',
        component: () => import(/* webpackChunkName: "internalPersonalYearEndSummary" */ '@/views/project/internalPersonalYearEndSummary/edit.vue'),
        meta: {
          title: '编辑个人年终总结'
        }
      },
      {
        path: 'internalPersonalYearEndSummary/detail/:id',
        name: 'internalPersonalYearEndSummaryDetail',
        component: () => import(/* webpackChunkName: "internalPersonalYearEndSummary" */ '@/views/project/internalPersonalYearEndSummary/detail.vue'),
        meta: {
          title: '个人年终总结详情'
        }
      },

      {
        path: 'monthlyPlan',
        name: 'monthlyPlan',
        component: () => import(/* webpackChunkName: "monthlyPlan" */ '@/views/project/monthlyPlan/list.vue'),
        meta: {
          title: '项目月计划表'
        }
      },

      {
        path: 'monthlyPlanCreate',
        name: 'monthlyPlan-create',
        component: () => import(/* webpackChunkName: "monthlyPlanCreate" */ '@/views/project/monthlyPlan/edit.vue'),
        meta: {
          title: '新建记录'
        }
      },
      {
        path: 'monthlyPlanEdit/:id',
        name: 'monthlyPlan-edit',
        component: () => import(/* webpackChunkName: "monthlyPlanEdit" */ '@/views/project/monthlyPlan/edit.vue'),
        meta: {
          title: '编辑记录'
        }
      },
      {
        path: 'monthlyPlanDetail/:id',
        name: 'monthlyPlan-detail',
        component: () => import(/* webpackChunkName: "monthlyPlanDetail" */ '@/views/project/monthlyPlan/detail.vue'),
        meta: {
          title: '项目月计划表详情'
        }
      },
      //外部项目月计划
      {
        path: 'externalMonthlySummarize',
        name: 'externalMonthlySummarize',
        component: () => import(/* webpackChunkName: "externalMonthlySummarize" */ '@/views/project/externalMonthlySummarize/list.vue'),
        meta: {
          title: '外部项目月总结管理'
        }
      },
      {
        path: 'externalMonthlySummarize/create',
        name: 'externalMonthlySummarizeCreate',
        component: () => import(/* webpackChunkName: "externalMonthlySummarize" */ '@/views/project/externalMonthlySummarize/edit.vue'),
        meta: {
          title: '新建记录'
        }
      },
      {
        path: 'externalMonthlySummarize/edit/:id',
        name: 'externalMonthlySummarizeEdit',
        component: () => import(/* webpackChunkName: "externalMonthlySummarize" */ '@/views/project/externalMonthlySummarize/edit.vue'),
        meta: {
          title: '编辑记录'
        }
      },
      {
        path: 'externalMonthlySummarize/detail/:id',
        name: 'externalMonthlySummarizeDetail',
        component: () => import(/* webpackChunkName: "externalMonthlySummarize" */ '@/views/project/externalMonthlySummarize/detail.vue'),
        meta: {
          title: '内部项目月总结详情'
        }
      },
      //
      {
        path: 'internalMonthlySummarize',
        name: 'internalMonthlySummarize',
        component: () => import(/* webpackChunkName: "internalMonthlySummarize" */ '@/views/project/internalMonthlySummarize/list.vue'),
        meta: {
          title: '内部项目月度总结报告'
        }
      },
      {
        path: 'internalMonthlySummarize/create',
        name: 'internalMonthlySummarizeCreate',
        component: () => import(/* webpackChunkName: "internalMonthlySummarize" */ '@/views/project/internalMonthlySummarize/edit.vue'),
        meta: {
          title: '新建月总结记录'
        }
      },
      {
        path: 'internalMonthlySummarize/edit/:id',
        name: 'internalMonthlySummarizeEdit',
        component: () => import(/* webpackChunkName: "internalMonthlySummarize" */ '@/views/project/internalMonthlySummarize/edit.vue'),
        meta: {
          title: '编辑记录'
        }
      },
      {
        path: 'internalMonthlySummarize/detail/:id',
        name: 'internalMonthlySummarizeDetail',
        component: () => import(/* webpackChunkName: "internalMonthlySummarize" */ '@/views/project/internalMonthlySummarize/detail.vue'),
        meta: {
          title: '内部项目月总结详情'
        }
      },
      {
        path: 'externalProject/letterIntent',
        name: 'externalProjectLetterIntent',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProject/letterIntent/list.vue'),
        meta: {
          title: '意向书管理'
        }
      },
      {
        path: 'externalProject/letterIntentCreate',
        name: 'externalProjectLetterIntent-create',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProject/letterIntent/edit.vue'),
        meta: {
          title: '新建记录'
        }
      },
      {
        path: 'externalProjectProcessMonitoring',
        name: 'externalProjectProcessMonitoring',
        component: () => import(/* webpackChunkName: "externalProjectProcessMonitoring" */ '@/views/project/externalProjectProcessMonitoring/list.vue'),
        meta: {
          title: '外部项目监测记录管理'
        }
      },
      {
        path: 'externalProjectProcessMonitoring/create/:projectId',
        name: 'externalProjectProcessMonitoring-create',
        component: () => import(/* webpackChunkName: "externalProjectProcessMonitoring" */ '@/views/project/externalProjectProcessMonitoring/edit.vue'),
        meta: {
          title: '编辑记录'
        }
      },
      {
        path: 'externalProjectProcessMonitoring/edit/:id',
        name: 'externalProjectProcessMonitoring-edit',
        component: () => import(/* webpackChunkName: "externalProjectProcessMonitoring" */ '@/views/project/externalProjectProcessMonitoring/edit.vue'),
        meta: {
          title: '编辑记录'
        }
      },
      {
        path: 'externalProject/letterIntentEdit/:id',
        name: 'externalProjectLetterIntent-edit',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProject/letterIntent/edit.vue'),
        meta: {
          title: '编辑记录'
        }
      },
      {
        path: 'externalProjectProcessMonitoringDetail',
        name: 'externalProjectProcessMonitoring-detail',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProjectProcessMonitoring/detail.vue'),
        meta: {
          title: '监测记录详情'
        }
      },
      {
        path: 'externalProject/letterIntentDetail/:id',
        name: 'externalProjectLetterIntent-detail',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProject/letterIntent/detail.vue'),
        meta: {
          title: '意向书管理详情'
        }
      },
      {
        path: 'externalProject/progressReports',
        name: 'externalProject-progressReports',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProject/progressReports/index.vue'),
        meta: {
          title: '中期总结报告'
        }
      },
      {
        path: 'externalProject/progressReports/create',
        name: 'externalProject-progressReports-create',
        component: () => import(/* webpackChunkName: "externalProject" */'@/views/project/externalProject/progressReports/edit.vue'),
        meta: {
          title: '创建中期总结报告'
        }
      },
      {
        path: 'externalProject/progressReports/edit/:id',
        name: 'externalProject-progressReports-edit',
        component: () => import(/* webpackChunkName: "externalProject" */'@/views/project/externalProject/progressReports/edit.vue'),
        meta: {
          title: '修改中期总结报告'
        }
      },
      {
        path: 'externalProject/progressReports/detail/:id',
        name: 'externalProject-progressReports-detail',
        component: () => import(/* webpackChunkName: "externalProject" */'@/views/project/externalProject/progressReports/detail.vue'),
        meta: {
          title: '中期总结详情'
        }
      },
      // 外部项目
      {
        path: 'projectInvite/list',
        name: 'projectInviteList',
        component: () => import(/* webpackChunkName: "projectInvite" */'@/views/project/projectInvite/list.vue'),
        meta: {
          title: '项目邀约管理'
        }
      },
      {
        path: 'projectInvite/create',
        name: 'projectInviteCreate',
        component: () => import(/* webpackChunkName: "projectInvite" */ '@/views/project/projectInvite/edit.vue'),
        meta: {
          title: '创建项目邀约'
        }
      },
      {
        path: 'projectInvite/detail/:id',
        name: 'projectInviteDetail',
        component: () => import(/* webpackChunkName: "projectInvite" */ '@/views/project/projectInvite/detail.vue'),
        meta: {
          title: '查看项目邀约'
        }
      },
      // 内部项目
      {
        path: 'projectInvite/list/:type',
        name: 'projectInviteListInternal',
        component: () => import(/* webpackChunkName: "projectInvite" */ '@/views/project/projectInvite/list.vue'),
        meta: {
          title: '项目邀约管理'
        }
      },
      {
        path: 'customForm',
        name: 'projectCustomForm',
        component: () => import(/* webpackChunkName: "customForm" */ '@/views/projectCustomForm/index.vue'),
        meta: {
          title: '项目自定义表单管理'
        },
      },
      {
        path: 'externalProject/notifyRecord/assessmentPlan/:type',
        name: 'externalProjectNotifyRecordAssessmentPlan',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProject/notifyRecord/list.vue'),
        meta: {
          title: '评估计划通知'
        }
      },
      {
        path: 'externalProject/notifyRecord-create/assessmentPlan/:type',
        name: 'externalProjectNotifyRecordCreateAssessmentPlan',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProject/notifyRecord/edit.vue'),
        meta: {
          title: '创建通知'
        }
      },
      {
        path: 'externalProject/notifyRecord-detail/assessmentPlan/:type/:id',
        name: 'externalProjectNotifyRecordDetailAssessmentPlan',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProject/notifyRecord/detail.vue'),
        meta: {
          title: '通知详情'
        }
      },
      {
        path: 'externalProject/notifyRecord/investigation/:type',
        name: 'externalProjectNotifyRecordInvestigation',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProject/notifyRecord/list.vue'),
        meta: {
          title: '尽职调查通知'
        }
      },
      // {
      //   path: 'externalProject/notifyRecord-create/investigation/:type',
      //   name: 'externalProjectNotificationCreateInvestigation',
      //   component: () => import(/* webpackChunkName: "ouUserManager" */ '@/views/project/externalProject/notifyRecord/editBeneficiary.vue'),
      //   meta: {
      //     title: '创建尽职调查通知'
      //   }
      // },
      // {
      //   path: 'externalProject/notifyRecord-detail/investigation/:type/:id',
      //   name: 'externalProjectNotificationDetailInvestigation',
      //   component: () => import(/* webpackChunkName: "ouUserManager" */ '@/views/project/externalProject/notifyRecord/detail.vue'),
      //   meta: {
      //     title: '尽职调查通知详情'
      //   }
      // }
      {
        path: 'externalProject/proposal',
        name: 'externalProjectProposal',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProject/proposal/list.vue'),
        meta: {
          title: '计划书管理'
        }
      },
      {
        path: 'externalProjectWebInfo/list',
        name: 'externalProjectWebInfoList',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProject/projectWebInfo/list.vue'),
        meta: {
          title: '项目网站信息'
        }
      },
      {
        path: 'externalProjectInfo/list',
        name: 'externalProjectInfoList',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProject/projectInfo/list.vue'),
        meta: {
          title: '项目信息管理'
        }
      },
      {
        path: 'externalProject/proposalCreate',
        name: 'externalProjectProposal-create',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProject/proposal/edit.vue'),
        meta: {
          title: '新建记录'
        }
      },
      {
        path: 'externalProject/proposalEdit/:id',
        name: 'externalProjectProposal-edit',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProject/proposal/edit.vue'),
        meta: {
          title: '编辑记录'
        }
      },
      {
        path: 'externalProject/proposalDetail/:id',
        name: 'externalProjectProposal-detail',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProject/proposal/detail.vue'),
        meta: {
          title: '项目计划书详情'
        }
      },
      {
        path: 'externalProject/proposalDetailWithoutLayout/:id',
        name: 'externalProjectProposal-detail-no-layout',
        component: () => import(/* webpackChunkName: "externalProject-proposalDetailWithoutLayout" */ '@/views/project/externalProject/proposal/detailWithoutLayout.vue'),
        meta: {
          title: '项目计划书详情'
        }
      },
      {
        path: 'externalProjectSummaryReport/list',
        name: 'externalProjectSummaryReportList',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProjectSummaryReport/list.vue'),
        meta: {
          title: '项目总结自评报告管理'
        }
      },
      {
        path: 'externalProjectSummaryReport/create',
        name: 'externalProjectSummaryReportCreate',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProjectSummaryReport/edit.vue'),
        meta: {
          title: '新建项目总结自评报告'
        }
      },
      {
        path: 'externalProjectSummaryReport/edit/:id',
        name: 'externalProjectSummaryReportEdit',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProjectSummaryReport/edit.vue'),
        meta: {
          title: '修改项目总结自评报告'
        }
      },
      {
        path: 'externalProjectSummaryReport/detail/:id',
        name: 'externalProjectSummaryReportDetail',
        component: () => import(/* webpackChunkName: "externalProject" */ '@/views/project/externalProjectSummaryReport/detail.vue'),
        meta: {
          title: '查看项目总结自评报告'
        }
      },
      {
        path: 'externalProjectCloseReport/list',
        name: 'externalProjectCloseReportList',
        component: () => import(/* webpackChunkName: "externalProjectCloseReport" */ '@/views/project/externalProjectCloseReport/list.vue'),
        meta: {
          title: '结项评估报告管理'
        }
      },
      {
        path: 'externalProjectCloseReport/create',
        name: 'externalProjectCloseReportCreate',
        component: () => import(/* webpackChunkName: "externalProjectCloseReport" */ '@/views/project/externalProjectCloseReport/edit.vue'),
        meta: {
          title: '新建项目结项评估报告'
        }
      },
      {
        path: 'externalProjectCloseReport/edit/:id',
        name: 'externalProjectCloseReportEdit',
        component: () => import(/* webpackChunkName: "externalProjectCloseReport" */ '@/views/project/externalProjectCloseReport/edit.vue'),
        meta: {
          title: '修改项目结项评估报告'
        }
      },
      {
        path: 'externalProjectCloseReport/detail/:id',
        name: 'externalProjectCloseReportDetail',
        component: () => import(/* webpackChunkName: "externalProjectCloseReport" */ '@/views/project/externalProjectCloseReport/detail.vue'),
        meta: {
          title: '查看项目结项评估报告'
        }
      },
      {
        path: 'internalProjectSummaryReports/list',
        name: 'internalProjectSummaryReportsList',
        component: () => import(/* webpackChunkName: "internalProjectSummaryReports" */ '@/views/project/internalProject/summaryReports/list.vue'),
        meta: {
          title: '项目总结报告'
        }
      },
      {
        path: 'internalProjectSummaryReports/create',
        name: 'internalProjectSummaryReportsCreate',
        component: () => import(/* webpackChunkName: "internalProjectSummaryReports" */ '@/views/project/internalProject/summaryReports/edit.vue'),
        meta: {
          title: '新建项目总结报告'
        }
      },
      {
        path: 'internalProjectSummaryReports/edit/:id',
        name: 'internalProjectSummaryReportsEdit',
        component: () => import(/* webpackChunkName: "internalProjectSummaryReports" */ '@/views/project/internalProject/summaryReports/edit.vue'),
        meta: {
          title: '修改项目总结报告'
        }
      },
      {
        path: 'internalProjectSummaryReports/detail/:id',
        name: 'internalProjectSummaryReportsDetail',
        component: () => import(/* webpackChunkName: "internalProjectSummaryReports" */ '@/views/project/internalProject/summaryReports/detail.vue'),
        meta: {
          title: '查看项目总结报告'
        }
      },
      {
        path: 'dueDiligenceRecordList',
        name: 'dueDiligenceRecordList',
        component: () => import(/* webpackChunkName: "internalProjectSummaryReports" */'@/views/project/dueDiligenceRecord/index.vue'),
        meta: {
          title: '尽职调查报告列表'
        }
      },
      {
        path: 'dueDiligenceRecord/create',
        name: 'dueDiligenceRecordCreate',
        component: () => import(/* webpackChunkName: "internalProjectSummaryReports" */'@/views/project/dueDiligenceRecord/edit.vue'),
        meta: {
          title: '创建尽职报告'
        }
      },
      {
        path: 'dueDiligenceRecord/edit/:id',
        name: 'dueDiligenceRecordEdit',
        component: () => import(/* webpackChunkName: "internalProjectSummaryReports" */'@/views/project/dueDiligenceRecord/edit.vue'),
        meta: {
          title: '修改尽职报告'
        }
      },
      {
        path: 'dueDiligenceRecord/detail/:id',
        name: 'dueDiligenceRecordDetail',
        component: () => import(/* webpackChunkName: "internalProjectSummaryReports" */'@/views/project/dueDiligenceRecord/detail.vue'),
        meta: {
          title: '尽职报告详情'
        }
      },
      // 外部项目
      {
        path: 'partnerCapitalApply/list',
        name: 'partnerCapitalApplyList',
        component: () => import(/* webpackChunkName: "partnerCapitalApply" */ '@/views/project/partnerCapitalApply/externalList.vue'),
        meta: {
          title: '合作方资金申请管理',
          keepAlive: true
        }
      },
      {
        path: 'partnerCapitalApply/create',
        name: 'partnerCapitalApplyCreate',
        component: () => import(/* webpackChunkName: "partnerCapitalApply" */ '@/views/project/partnerCapitalApply/edit.vue'),
        meta: {
          title: '新建合作方资金申请'
        }
      },
      {
        path: 'partnerCapitalApply/edit/:id',
        name: 'partnerCapitalApplyEdit',
        component: () => import(/* webpackChunkName: "partnerCapitalApply" */ '@/views/project/partnerCapitalApply/edit.vue'),
        meta: {
          title: '修改合作方资金申请'
        }
      },
      {
        path: 'partnerCapitalApply/detail/:id',
        name: 'partnerCapitalApplyDetail',
        component: () => import(/* webpackChunkName: "partnerCapitalApply" */ '@/views/project/partnerCapitalApply/detail.vue'),
        meta: {
          title: '合作方资金申请详情'
        }
      },
      // 内部项目
      {
        path: 'partnerCapitalApply/list/:type',
        name: 'partnerCapitalApplyListInternal',
        component: () => import(/* webpackChunkName: "partnerCapitalApply" */ '@/views/project/partnerCapitalApply/internalList.vue'),
        meta: {
          title: '合作方资金申请管理',
          keepAlive: true
        }
      },
      {
        path: 'partnerCapitalApplySettlement/list/:type',
        name: 'partnerCapitalApplySettlementListInternal',
        component: () => import(/* webpackChunkName: "partnerCapitalApply" */ '@/views/project/partnerCapitalApplySettlement/internalList.vue'),
        meta: {
          title: '合作方资金申请结算',
          keepAlive: true
        }
      },
      {
        path: 'partnerCapitalApplySettlement/create',
        name: 'partnerCapitalApplySettlementCreate',
        component: () => import(/* webpackChunkName: "partnerCapitalApply" */ '@/views/project/partnerCapitalApplySettlement/edit.vue'),
        meta: {
          title: '新建合作方资金申请结算'
        }
      },
      {
        path: 'partnerCapitalApplySettlement/edit/:id',
        name: 'partnerCapitalApplySettlementEdit',
        component: () => import(/* webpackChunkName: "partnerCapitalApply" */ '@/views/project/partnerCapitalApplySettlement/edit.vue'),
        meta: {
          title: '修改合作方资金申请结算'
        }
      },
      {
        path: 'partnerCapitalApplySettlement/detail/:id',
        name: 'partnerCapitalApplySettlementDetail',
        component: () => import(/* webpackChunkName: "partnerCapitalApply" */ '@/views/project/partnerCapitalApplySettlement/detail.vue'),
        meta: {
          title: '合作方资金申请结算详情'
        }
      },



      {
        path: 'internalProjectProposal/list',
        name: 'internalProjectProposalList',
        component: () => import(/* webpackChunkName: "internalProjectProposal" */ '@/views/project/internalProject/projectProposal/list.vue'),
        meta: {
          title: '项目立项管理'
        }
      },
      {
        path: 'internalProjectWebInfo/list',
        name: 'internalProjectWebInfoList',
        component: () => import(/* webpackChunkName: "internalProjectProposal" */ '@/views/project/internalProject/projectWebInfo/list.vue'),
        meta: {
          title: '项目网站信息'
        }
      },
      {
        path: 'internalProjectInfo/list',
        name: 'internalProjectInfoList',
        component: () => import(/* webpackChunkName: "internalProjectProposal" */ '@/views/project/internalProject/projectInfo/list.vue'),
        meta: {
          title: '项目信息管理'
        }
      },
      {
        path: 'internalProjectProposal/edit',
        name: 'internalProjectProposalEdit',
        component: () => import(/* webpackChunkName: "internalProjectProposal" */ '@/views/project/internalProject/projectProposal/index.vue'),
        meta: {
          title: '编辑项目立项'
        }
      },
      {
        path: 'internalProjectProposal/detail',
        name: 'internalProjectProposalDetail',
        component: () => import(/* webpackChunkName: "internalProjectProposal" */ '@/views/project/internalProject/projectProposal/customDetail.vue'),
        meta: {
          title: '查看项目立项'
        }
      },
      {
        path: 'internalProjectBeneficiary/list',
        name: 'internalProjectBeneficiaryList',
        component: () => import(/* webpackChunkName: "internalProjectBeneficiary" */ '@/views/project/internalProject/beneficiary/list.vue'),
        meta: {
          title: '受益人管理',
          keepAlive: true
        }
      },
      {
        path: 'internalProjectBeneficiary/create',
        name: 'internalProjectBeneficiaryCreate',
        component: () => import(/* webpackChunkName: "internalProjectBeneficiary" */ '@/views/project/internalProject/beneficiary/edit.vue'),
        meta: {
          title: '新建受益人'
        }
      },
      {
        path: 'internalProjectBeneficiary/edit/:id',
        name: 'internalProjectBeneficiaryEdit',
        component: () => import(/* webpackChunkName: "internalProjectBeneficiary" */ '@/views/project/internalProject/beneficiary/edit.vue'),
        meta: {
          title: '修改受益人'
        }
      },
      {
        path: 'internalProjectBeneficiary/detail/:id',
        name: 'internalProjectBeneficiaryDetail',
        component: () => import(/* webpackChunkName: "internalProjectBeneficiary" */ '@/views/project/internalProject/beneficiary/detail.vue'),
        meta: {
          title: '查看受益人'
        }
      },
      {
        path: 'internalProjectProposalBeneficiary/list',
        name: 'internalProjectProposalBeneficiaryList',
        component: () => import(/* webpackChunkName: "internalProjectProposalBeneficiary" */ '@/views/project/internalProject/projectProposal/beneficiary/list.vue'),
        meta: {
          title: '项目受益人管理',
          keepAlive: true
        }
      },
      {
        path: 'internalProjectProposalBeneficiary/create',
        name: 'internalProjectProposalBeneficiaryCreate',
        component: () => import(/* webpackChunkName: "internalProjectProposalBeneficiary" */ '@/views/project/internalProject/projectProposal/beneficiary/index.vue'),
        meta: {
          title: '新建项目受益人'
        }
      },
      {
        path: 'internalProjectProposalBeneficiary/add',
        name: 'internalProjectProposalBeneficiaryAdd',
        component: () => import(/* webpackChunkName: "internalProjectProposalBeneficiary" */ '@/views/project/internalProject/projectProposal/beneficiary/add.vue'),
        meta: {
          title: '添加已有受益人'
        }
      },
      {
        path: 'internalProjectProposalBeneficiary/edit',
        name: 'internalProjectProposalBeneficiaryEdit',
        component: () => import(/* webpackChunkName: "internalProjectProposalBeneficiary" */ '@/views/project/internalProject/projectProposal/beneficiary/add.vue'),
        meta: {
          title: '修改项目受益人'
        }
      },
      {
        path: 'internalProjectProposalBeneficiary/detail',
        name: 'internalProjectProposalBeneficiaryDetail',
        component: () => import(/* webpackChunkName: "internalProjectProposalBeneficiary" */ '@/views/project/internalProject/projectProposal/beneficiary/detail.vue'),
        meta: {
          title: '查看项目受益人'
        }
      },
      {
        path: 'internalProjectBeneficiaryServiceRecord/list',
        name: 'internalProjectBeneficiaryServiceRecordList',
        component: () => import(/* webpackChunkName: "internalProjectBeneficiaryServiceRecord" */ '@/views/project/internalProject/beneficiaryServiceRecord/list.vue'),
        meta: {
          title: '项目受益人服务记录列表',
          keepAlive: true
        }
      },
      {
        path: 'beneficiaryServiceList/list',
        name: 'beneficiaryServiceList',
        component: () => import(/* webpackChunkName: "ouUserManager" */ '@/views/project/internalProject/beneficiaryServiceRecord/components/beneficiaryServiceList.vue'),
        meta: {
          title: '受益人服务记录列表',
        }
      },
      {
        path: 'internalProjectBeneficiaryServiceRecord/index',
        name: 'internalProjectBeneficiaryServiceRecordIndex',
        component: () => import(/* webpackChunkName: "internalProjectBeneficiaryServiceRecord" */ '@/views/project/internalProject/beneficiaryServiceRecord/index.vue'),
        meta: {
          title: '受益人服务记录管理'
        }
      },
      {
        path: 'internalProjectBeneficiaryServiceRecord/create',
        name: 'internalProjectBeneficiaryServiceRecordCreate',
        component: () => import(/* webpackChunkName: "internalProjectBeneficiaryServiceRecord" */ '@/views/project/internalProject/beneficiaryServiceRecord/editBeneficiary.vue'),
        meta: {
          title: '新建受益人服务记录'
        }
      },
      {
        path: 'internalProjectBeneficiaryServiceRecord/edit',
        name: 'internalProjectBeneficiaryServiceRecordEdit',
        component: () => import(/* webpackChunkName: "internalProjectBeneficiaryServiceRecord" */ '@/views/project/internalProject/beneficiaryServiceRecord/editBeneficiary.vue'),
        meta: {
          title: '修改受益人服务记录'
        }
      },
      {
        path: 'internalProjectBeneficiaryServiceRecord/index/:type',
        name: 'internalProjectBeneficiaryServiceRecordIndex',
        component: () => import(/* webpackChunkName: "internalProjectBeneficiaryServiceRecord" */ '@/views/project/internalProject/beneficiaryServiceRecord/index.vue'),
        meta: {
          title: '组织服务记录管理'
        }
      },
      {
        path: 'internalProjectSchoolServiceRecord/create',
        name: 'internalProjectSchoolServiceRecordCreate',
        component: () => import(/* webpackChunkName: "internalProjectSchoolServiceRecord" */ '@/views/project/internalProject/beneficiaryServiceRecord/editSchool.vue'),
        meta: {
          title: '新建组织服务记录'
        }
      },
      {
        path: 'internalProjectSchoolServiceRecord/edit',
        name: 'internalProjectSchoolServiceRecordEdit',
        component: () => import(/* webpackChunkName: "internalProjectSchoolServiceRecord" */ '@/views/project/internalProject/beneficiaryServiceRecord/editSchool.vue'),
        meta: {
          title: '修改组织服务记录'
        }
      },
      {
        path: 'internalProjectSchoolServiceRecord/detail',
        name: 'internalProjectSchoolServiceRecordDetail',
        component: () => import(/* webpackChunkName: "internalProjectSchoolServiceRecord" */ '@/views/project/internalProject/beneficiaryServiceRecord/detail.vue'),
        meta: {
          title: '查看服务记录'
        }
      },
      {
        path: 'result/edit/:projectId',
        name: 'editProjectResult',
        component: () => import(/* webpackChunkName: "project" */ '@/views/project/common/ProjectResultEdit.vue'),
        meta: {
          title: '项目成果维护'
        }
      },
      {
        path: 'internalProjectPhases/list',
        name: 'internalProjectPhasesList',
        component: () => import(/* webpackChunkName: "internalProjectPhases" */ '@/views/project/internalProject/phases/list.vue'),
        meta: {
          title: '项目周期'
        }
      },
      {
        path: 'internalProjectPhases/index/:id',
        name: 'internalProjectPhasesIndex',
        component: () => import(/* webpackChunkName: "internalProjectPhases" */ '@/views/project/internalProject/phases/index.vue'),
        meta: {
          title: '查看项目周期'
        }
      },
      {
        path: 'externalProjectPhases/list',
        name: 'externalProjectPhasesList',
        component: () => import(/* webpackChunkName: "externalProjectPhases" */ '@/views/project/externalProject/phases/list.vue'),
        meta: {
          title: '项目周期'
        }
      },
      {
        path: 'externalProjectPhases/index/:id',
        name: 'externalProjectPhasesIndex',
        component: () => import(/* webpackChunkName: "externalProjectPhases" */ '@/views/project/externalProject/phases/index.vue'),
        meta: {
          title: '查看项目周期'
        }
      },
    ]
  }
]


export default projectRouter;
