import moment from 'moment'
import {OrganizationUnitDto} from "@/api/appService";

moment.locale('zh-cn')

function formatDate(value: any, arg: string | undefined) {
  if (value) {
    if (arg) {
      if (arg === 'fromNow') {
        return moment(String(value)).fromNow()
      }
      return moment(String(value)).format(arg)
    }
    return moment(String(value)).format('YYYY-MM-DD HH:mm')
  }
}

function formatYearMonthDayDate(value: any, arg: string | undefined) {
  if (value) {
    if (arg) {
      if (arg === 'fromNow') {
        return moment(String(value)).fromNow()
      }
      return moment(String(value)).format(arg)
    }
    return moment(String(value)).format('YYYY-MM-DD')
  }
}

function formatYearMonth(value: any) {
  if (value)
    return moment(value).format('YYYY-MM');
}
function formatYearMonthData(value: any) {
  if (value)
    return moment(value).format('YYYY年MM月');
}
function currency(value: any) {
  if (value) {
    return '￥' + parseFloat(value).toFixed(2)
  }
  return value
}

function formatPartnerOrg(orgIds: string, orgList: OrganizationUnitDto[]) {
  const displayNames: string[] = [];
  orgList.map((item: any) => {
    if (orgIds.indexOf(item.id) > -1) {
      displayNames.push(item.displayName);
    }
  });
  return displayNames.join(",");
}

function formatIncomeType(incomeType?: string) {
  switch (incomeType) {
    case "Cash":
      return "现金";

    case "Transfer":
      return "转账";

    case "Material":
      return "物资";
  }
}

function formatCapitalProperty(capitalProperty?: string) {
  switch (capitalProperty) {
    case "NonFinite":
      return "非限定性";

    case "Finite":
      return "限定性";
  }
}

function formatIncomeCategory(incomeCategory?: string) {
  switch (incomeCategory) {
    case "Interest":
      return "利息";

    case "Investment":
      return "投资收益";

    case "Other":
      return "其他";
  }
}

function formatCurrencyType(currencyType?: string) {
  switch (currencyType) {
    case "RMB":
      return "人民币";
  }
}

function formatCurrency(value?: number) {

  value = Math.round(Number(value) * Math.pow(10, 2)) / Math.pow(10, 2) //四舍五入
  const currency = Number(value).toFixed(2); //不足补位
  return currency;

}

function getSingleColumnSum(list?: any[]) {
  let sum: number = 0;
  list!.map((item, index) => {
    if (item.money)
      sum += parseFloat(item.money);
  });
  return sum;
}

function formatEducationType(value?:number,){

}

function formatMoney(value?:any,){
  if (!value) {
    return value;
  }
  value = Math.round(Number(value) * Math.pow(10, 2)) / Math.pow(10, 2) //四舍五入
  const money = value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
  return money;
}

export default {
  formatDate,
  formatYearMonthDayDate,
  formatYearMonth,
  formatYearMonthData,
  currency,
  formatPartnerOrg,
  formatIncomeType,
  formatCapitalProperty,
  formatCurrencyType,
  formatCurrency,
  formatIncomeCategory,
  getSingleColumnSum,
  formatMoney

}
