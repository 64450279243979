import request from '../../utils/request'

export default {
  getAll: data => request({
    url: '/api/services/app/payrollRequisition/GetAll',
    method: 'get',
    params: data
  }),
  get: data => request({
    url: '/api/services/app/payrollRequisition/Get',
    method: 'get',
    params: data
  }),
  getForEdit: data => request({
     url: '/api/services/app/payrollRequisition/GetForEdit',
     method: 'get',
     params: data
  }),
  create: data => request({
    url: '/api/services/app/payrollRequisition/Create',
    method: 'post',
    data: data
  }),
  update: data => request({
    url: '/api/services/app/payrollRequisition/Update',
    method: 'put',
    data: data
  }),
  delete: function(data) {
    return request({
      url: '/api/services/app/payrollRequisition/delete',
      method: 'delete',
      params: data
    })
  }
}
