const accountRoutes = [
  {
    name: "login",
    path: "/account/login",
    component: () => import("@/views/account/login.vue"),
    meta: {
      auth: false,
      title: "登录",
    },
  }
];

export default accountRoutes;
