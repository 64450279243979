
import path from 'path'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Route, RouteConfig } from 'vue-router'
import { isExternal } from '@/utils/validate'
import SidebarItemLink from './SidebarItemLink.vue'
import {IPermissionItem} from "@/store/modules/permission";

@Component({
  // Set 'name' here to prevent uglifyjs from causing recursive component not work
  // See https://medium.com/haiiro-io/element-component-name-with-vue-class-component-f3b435656561 for detail
  name: 'SidebarItem',
  components: {
    SidebarItemLink
  }
})
export default class extends Vue {
  @Prop({ required: true }) private item!: IPermissionItem;
  @Prop({ default: false }) private isCollapse!: boolean;
  @Prop({ default: true }) private isFirstLevel!: boolean;
  @Prop({ default: '' }) private basePath!: string;

  get showingChildNumber() {
    return this.item.children?.length??0;
  }

  get onlyItem(){
    if(this.item.children && this.item.children.length){
      return null;
    } else {
      return this.item;
    }
  }

  get theOnlyOneChild() {
    if (this.showingChildNumber > 1) {
      return null
    }

    if(this.item.children && this.item.children.length){
      return this.item.children![0];
    }


    // If there is no children, return itself with path removed,
    // because this.basePath already conatins item's path information
    return { ...this.item }
  }

  private resolvePath(routePath: string) {
    if (isExternal(routePath)) {
      return routePath
    }
    if (isExternal(this.basePath)) {
      return this.basePath
    }
    return path.resolve(this.basePath, routePath??'')
  }
}
