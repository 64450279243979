
import {Component, Vue} from "vue-property-decorator";
import router, {asyncRouter} from "./router/index";
import {log} from "util";

import {UserModule} from "@/store/modules/user";
import {AppModule} from "@/store/modules/app";
import {AuthModule} from "@/store/modules/auth";

import {auth} from "@/services/index";

@Component({
  name: "App"
})
export default class extends Vue {
  signedIn: boolean = true;

  beforeCreate() {
    auth.getUser().then(user => {
      if (user == null) {
        auth.login();
      } else {
        AuthModule.Set_AuthUser(user);
      }
    });
  }
}
