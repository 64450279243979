import {
  RouteConfig
} from 'vue-router'
import Layout from '@/layout/index.vue'

const organizationRouter: RouteConfig[] = [
  {
    path: '/organization',
    component: Layout,
    name: 'organization',
    meta: {
      title: '合作方'
    },
    children: [
      {
        path: 'list',
        name: 'organizationList',
        component: () => import(/* webpackChunkName: "organization" */'@/views/organization/list.vue'),
        meta: {
          title: '合作方列表'
        }
      },
      {
        path: 'create',
        name: 'organizationCreate',
        component: () => import(/* webpackChunkName: "organization" */'@/views/organization/edit.vue'),
        meta: {
          title: '创建合作方'
        }
      },
      {
        path: 'edit/:id',
        name: 'organizationEdit',
        component: () => import(/* webpackChunkName: "organization" */'@/views/organization/edit.vue'),
        meta: {
          title: '修改合作方'
        }
      },
      {
        path: 'detail',
        name: 'orgDetail',
        component: () => import(/* webpackChunkName: "organization" */'@/views/organization/detail.vue'),
        meta: {
          title: '合作方详情'
        }
      },
      {
        path: 'detail/:id',
        name: 'organizationDetail',
        component: () => import(/* webpackChunkName: "organization" */'@/views/organizationApply/detail.vue'),
        meta: {
          title: '合作方详情'
        }
      },
      {
        path: 'import/result/:reportName',
        name: 'uploadTemplateDetail',
        component: () => import(/* webpackChunkName: "organization" */'@/components/UploadTemplate/result.vue'),
        meta: {
          title: '模板导入结果'
        }
      }
    ]
  }
]


export default organizationRouter;
