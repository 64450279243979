import Vue from 'vue'
import Vuex, {StoreOptions} from 'vuex'
// import { IAppState } from './modules/app'
// import { IUserState } from './modules/user'
import {UserModule} from './modules/user';
import {SettingsModule} from "@/store/modules/settings";
import {AppModule} from "@/store/modules/app";
import {PermissionModule} from '@/store/modules/permission';


Vue.use(Vuex)

interface StoreType {
  app: any
  user: any
  permission: any
  settings: any
}



// Declare empty store first, dynamically register all modules later.


export default new Vuex.Store<StoreType>({});
