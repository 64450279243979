
import Vue from 'vue'
import Pin from 'vue-pin';

import 'normalize.css'
import ElementUI from 'element-ui'
import SvgIcon from 'vue-svgicon'
import '@/styles/element-variables.scss'
import '@/styles/index.scss'

import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import '@/icons/components'
import '@/permission'
import filter from "@/utils/filter"
import permissions from '@/core/permission';

import areaAllApis from "@/api/area"

import '../public/ueditor/ueditor.config.js'
import '../public/ueditor/ueditor.all.js'
import '../public/ueditor/lang/zh-cn/zh-cn.js'
import '../public/ueditor/ueditor.parse.min.js'
import '../public/ueditor/themes/default/css/ueditor.min.css'

Vue.filter('formatDate', filter.formatDate)
Vue.filter('formatYearMonthDayDate', filter.formatYearMonthDayDate)
Vue.filter('formatYearMonthData', filter.formatYearMonthData)
Vue.filter('currency', filter.currency)
Vue.filter('formatPartnerOrg',filter.formatPartnerOrg)
Vue.filter('formatYearMonth',filter.formatYearMonth)
Vue.filter('formatCurrencyType',filter.formatCurrencyType)
Vue.filter('formatCapitalProperty',filter.formatCapitalProperty)
Vue.filter('formatIncomeType',filter.formatIncomeType)
Vue.filter('formatIncomeCategory',filter.formatIncomeCategory)
Vue.filter('formatCurrency',filter.formatCurrency)
Vue.filter('getSingleColumnSum',filter.getSingleColumnSum)
Vue.filter('formatMoney',filter.formatMoney)
Vue.prototype.$uploadUrl = process.env.VUE_APP_BASE_API + '/api/services/app/Client/Upload?id=2'
Vue.prototype.$abpApi = areaAllApis;
Vue.prototype.baseURL = process.env.VUE_APP_BASE_API;
Vue.prototype.$permissions = permissions;
Vue.prototype.hasPermission = hasPermission;
Vue.use(ElementUI);
Vue.use(Pin);
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'/**/
});

const eventBus = new Vue();
(window as any).$eventBus = eventBus;
Vue.prototype.$bus = eventBus;

// 获取年月
Vue.prototype.$getCaption = function (obj: string, state: number = 0) {
  const index = obj.lastIndexOf("\-");
  if (state === 0) {
    obj = obj.substring(0, index);
  } else {
    obj = obj.substring(index + 1, obj.length);
  }
  return obj;
};


Vue.config.productionTip = false
Vue.prototype.$getActualPageIndex = function () {
  var historyParams = sessionStorage.getItem('HISTORY_SEARCH_PARAMS')
  var historyUrlSame = sessionStorage.getItem('HISTORY_SEARCH_URL_SAME')
  if (historyParams && Boolean(historyUrlSame)) {
    historyParams = JSON.parse(historyParams)
    // @ts-ignore
    return historyParams.PageInde
  }
  return -1
}

Vue.prototype.$getActualPageSize = function () {
  var historyParams = sessionStorage.getItem('HISTORY_SEARCH_PARAMS')
  var historyUrlSame = sessionStorage.getItem('HISTORY_SEARCH_path')
  console.log(location.hash, 'this.$route.path')
  if (historyParams && location.hash === historyUrlSame) {
    historyParams = JSON.parse(historyParams)
    // @ts-ignore
    return historyParams.PageSize
  }
  return -1
}


Vue.prototype.$clearHistorySearchParams = function () {
  sessionStorage.setItem('HISTORY_SEARCH_PARAMS', '')
  sessionStorage.setItem('HISTORY_SEARCH_URL', '')
}


Vue.directive('permission',{
  bind:(el,binding,vnode)=>{
    const permissionName = binding.value;
    const permissions = store.state.user.permissions;
    if(!hasPermission(permissionName)){
      el.style.display = 'none';
    }

  },
  update(el,binding,vnode){
    console.log(' directive:permission update');
    const permissionName = binding.value;
    const permissions = store.state.user.permissions;
    if(!hasPermission(permissionName)){
      el.style.display = 'none';
    } else {
      el.style.display = '';
    }
  }
})

function hasPermission(permissionName:string) :boolean{
  const permissions = store.state.user.permissions;
  if(!(permissions
    && permissions instanceof Array
    && permissions.length
    && permissions.some(name=>name === permissionName))){
    return false;
  } else {
    return true;
  }
}

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')




