import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {RouteConfig} from 'vue-router'
import store from '@/store'
import {constantRoutes} from '@/router'
import {PermissionDefinitionDto, PermissionDefinitionScope} from "@/api/appService";
import {createNgTree, INgNode} from "@/utils/tree";

export interface IPermissionItem {
  displayOrder:number;
  name:string;
  displayName:string;
  children?:IPermissionItem[];
  icon?:string;
  url?:string;
  id:number
}




function filterPermission(permissions:string[],permissionDefinitions:INgNode<PermissionDefinitionDto>[], scope:PermissionDefinitionScope){
  let res :IPermissionItem[] = [];
  permissionDefinitions.forEach(definition=>{
    if(!(definition.data.permissionDefinitionScopes?.some(s=>s == scope))??false){
      // don't do anything
      return;
    }
    if(definition.data.isHiddenForMenu){
      // don't do anything
      return;
    }


    let permissionItem:IPermissionItem | undefined;
    if(permissions.some(name=>name === definition.data.name)){
      permissionItem = {
        id:definition.id!,
        name:definition.data.name!,
        displayName:definition.data.displayName!,
        displayOrder:0,
        icon: definition.data.icon,
        children:[],
        url:definition.data.url
      };
      let children = definition.children;
      if(children && children.length){
        permissionItem.children = filterPermission(permissions,children,scope);
      }
    }

    if(permissionItem){
      res.push(permissionItem);
    }
  });

  return res;
}


export interface IPermissionState {
  routes: RouteConfig[]
  dynamicRoutes: RouteConfig[]
}

// Create module later in your code (it will register itself automatically)
// In the decorator we pass the store object into which module is injected
// NOTE: When you set dynamic true, make sure you give module a name
@Module({ dynamic:true, store, name: 'permission' })
class Permission extends VuexModule {

  public routes: RouteConfig[] = [];

  public dynamicRoutes: RouteConfig[] = [];

  public menus: IPermissionItem[] = [];


  @Mutation
  private SET_ROUTES(routes: RouteConfig[]) {
    this.dynamicRoutes = routes;
    this.routes = [...constantRoutes!, ...routes];
  }

  @Mutation
  private SET_MENUS(menus: IPermissionItem[]){
    this.menus = menus;
  }

  @Action
  public GenerateMenus(input: { permissions: string[], roles: string[], type: string, permissionDefinitions:PermissionDefinitionDto[] }) {
    if (input.permissions !== undefined) {
      let accessedMenus;
      const permissionTree = createNgTree<PermissionDefinitionDto>(
        input.permissionDefinitions,
        'parentId',
        'id',
        null,
        'children',
        '',
        false,
        'id');

      let permissionScope:PermissionDefinitionScope;

      switch (input.type) {
        case 'Partner':
          permissionScope = PermissionDefinitionScope.Partner;
          break;
        case 'Foundation':
          permissionScope = PermissionDefinitionScope.Foundation;
          break;
        default:
          permissionScope = PermissionDefinitionScope.Foundation;
          break;

      }

      accessedMenus = filterPermission(input.permissions!,permissionTree,permissionScope);
      this.SET_MENUS(accessedMenus)
    }
  }

}

export const PermissionModule = getModule(Permission);
