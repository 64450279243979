import request from '../../utils/request'

export default {
  getPlaceSuggestion: data => {
    const params = {
      query: data,
      region: '苏州市',
      type: 'gcj02ll'
    }
    return request({
      url: '/api/services/app/Client/GetPlaceSuggestion',
      method: 'get',
      params: params
    })
  },
  getUnLimitQr: data => {
    return request({
      url: '/api/services/app/Client/GetUnLimitQr',
      method: 'get',
      params: data
    })
  },
  getStores: data => {
    return request({
      url: '/api/services/app/Client/GetStores',
      method: 'get',
      params: data
    })
  }
}
