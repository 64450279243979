import {
  RouteConfig
} from 'vue-router'
import Layout from '@/layout/index.vue'

const organizationApplyRouter: RouteConfig[] = [
  {
    path: '/organizationApply',
    component: Layout,
    name: 'organizationApply',
    meta: {
      title: '合作方申请管理'
    },
    children: [
      {
        path: 'list',
        name: 'organizationApplyList',
        component: () => import(/* webpackChunkName: "ouApply" */ '@/views/organizationApply/list.vue'),
        meta: {
          title: '合作方申请列表'
        }
      },
      {
        path: 'edit/:id',
        name: 'organization-apply-edit',
        component: () => import(/* webpackChunkName: "ouApply" */  '@/views/organization/edit.vue'),
        meta: {
          title: '修改合作方'
        }
      },
      {
        path: 'detail/:id',
        name: 'organizationApplyDetail',
        component: () => import(/* webpackChunkName: "ouApply" */  '@/views/organizationApply/detail.vue'),
        meta: {
          title: '合作方申请详情'
        }
      }
    ]
  }
]


export default organizationApplyRouter;
