
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import api from "@/api";

import { UserModule} from "@/store/modules/user"
import { AppModule } from '../../../store/modules/app';

@Component({
  name: "SidebarLogo"
})
export default class SidebarLogo extends Vue {
  get collapse()
  {
    return !AppModule.sidebar.opened
  }

  get title() {
    return UserModule.ou!.displayName
  }

  get logo(){
    return UserModule.ou!.logoImageUrl;
  }
  created() {}
}
