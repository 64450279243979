
import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref
} from "vue-property-decorator";

import api from "@/api";
import { ChangePasswordDto } from "@/api/appService";
import { createNgTree, rebuildKeys, getKeys } from "@/utils/tree";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "ChangePassword"
})
export default class ChangePassword extends Vue {
  @Ref() readonly dataForm!: ElForm;

  defaultData: ChangePasswordDto = {
    currentPassword: undefined,
    newPassword: undefined
  };

  userId!: number;
  show = false;
  form: ChangePasswordDto = { ...this.defaultData };
  cancel() {
    this.show = false;
    this.form = { ...this.defaultData };
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        await api.user.changePassword({
          body: this.form
        });
        this.show = false;
        this.$message.success("更新成功");
      }
    });
  }

  ruleRule = {
    currentPassword: [
      {
        required: true,
        message: "请输入当前密码",
        trigger: "blur"
      }
    ],
    newPassword: [
      {
        required: true,
        message: "请输入新密码",
        trigger: "blur"
      }
    ]
  };
}
