import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import { removeToken } from '@/utils/cookies'
import { AppModule } from './app'
import store from '@/store'
import api from '@/api/index'
import router, { asyncRouter } from '@/router/index'

import { getOu, setOu } from '@/utils/cookies'

import { RouteConfig } from 'vue-router'
import { PermissionModule } from './permission'
import { UserLoginInfoDto, OrganizationUnitDto } from '@/api/appService'
import { setStore, getStore } from '@/utils/storage'
import Cookies from "js-cookie";

// export interface IUserState {
//   token: string
//   name: string
//   avatar: string
//   introduction: string
//   roles: string[]
//   user: any
// }

function getPermissionDefinitions(){
  return api.permissionDefinition.getAll({sorting:'displayOrder asc,id asc',maxResultCount:65536});
}

@Module({ dynamic:true, store, name: 'user' })
class User extends VuexModule {

  public token = ''
  public name = ''
  public avatar = ''
  public introduction = ''
  public roles: string[] = []
  public permissions: string[] = [];
  defineImg = require('@/assets/images/default-header.png')

  public ou?: OrganizationUnitDto = getStore<OrganizationUnitDto>("currentOrganization") || {
    displayName: "福建省恒申慈善基金会",
    logoImageUrl:this.defineImg
    // logoImageUrl:'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png',
  };

  get ouId() {
    let ouid = this.ou?.id ?? getOu();
    return ouid;
  }

  get getOU() {
    return this.ou;
  }

  public user: UserLoginInfoDto = { id: -1,  name: undefined, surname: undefined, userName: undefined, emailAddress: undefined};

  get getToken() {
    return this.token;
  }

  get getRoles() {
    return this.roles;
  }


  get getIsAdmin() {
    return this.roles.find(x => x === "Admin") ? true : false;
  }


  get getAvatar() {
    return '';
  }

  get getName() {
    return this.user.name;
  }

  get getFullName(){
    // this.user.surname as string +
    return  this.user.name as string;
  }

  get getPermissions() {
    return this.permissions;
  }

  @Mutation
  private SET_TOKEN(token: string) {
    console.log('SET_TOKEN');
    this.token = token
  }

  @Mutation
  private SET_ROLES(roles: string[] | string) {
    console.log('SET_ROLES');
    if (Array.isArray(roles))
      this.roles = [...roles]
    else
      this.roles = [roles];
  }


  @Mutation
  private SET_PERMISSIONS(permissions: string[]) {
    this.permissions = permissions;
  }

  @Mutation
  private SET_USER(user: any) {
    console.log('SET_USER');
    this.user = user
  }

  @Mutation
  private SET_OU(ou?: OrganizationUnitDto) {
    this.ou = ou
    setStore("currentOrganization", ou);
    setOu(ou?.id?.toString()??'');
  }


  @Action
  public SetToken(token: string) {
    this.SET_TOKEN(token)
  }

  @Action
  public SetRoles(roles: string[] | string) {
    if (Array.isArray(roles))
      this.SET_ROLES(roles)
    else
      this.SET_ROLES([roles])
  }

  @Action
  public Logout(){
    this.SET_OU(undefined);
    this.SET_TOKEN('');
    this.SET_PERMISSIONS([]);
    this.SET_ROLES([]);
    this.SET_USER(undefined);
    Cookies.set("accessToken","")
  }


  @Action
  public ResetToken() {
    removeToken()
    this.SET_TOKEN('')
    this.SET_ROLES([])
  }

  @Action
  public async GetUserInfo(roles: string[]) {
    await getPermissionDefinitions().then(permissionResponse=>{
      api.session.getCurrentLoginInformations().then((res) => {
        //console.log(res);
        store.commit('SET_USER',res.user);

        AppModule.SetTenant(res.tenant!);


        if (res.permissions){
          store.commit('SET_PERMISSIONS',res.permissions);
        }


        let organizationUnitType = "";
        if (res.organizationUnit) {
          organizationUnitType = res.organizationUnit!.organizationType!+'';
          store.commit('SET_OU',res.organizationUnit)
        } else {
          if (this.getIsAdmin ) {
            organizationUnitType = "Foundation"
          }
          store.commit('SET_OU',{
            displayName: "福建省恒申慈善基金会",
            logoImageUrl:this.defineImg
            // logoImageUrl:'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png',
          })
        }
        PermissionModule.GenerateMenus({ permissions: res.permissions!, roles: roles, type: organizationUnitType,permissionDefinitions: permissionResponse.items! });

      });
    });


  }
  @Action
  public Set_Ou(ou: OrganizationUnitDto) {
    debugger
    this.SET_OU(ou);
    setStore("currentOrganization", ou);
    getPermissionDefinitions().then(permissionResponse=>{
      PermissionModule.GenerateMenus({ permissions: this.permissions!, roles: this.roles, type: ou.organizationType!+'' ,permissionDefinitions:permissionResponse.items!});
    });


  }



}



export const UserModule = getModule(User)
