import request from '../../utils/request'

export default {
  get: data => request({
    url: '/api/services/app/OrganizationUnit/GetOrganizationUnit',
    method: 'get',
    params: data
  }),
  getOrganizationUnits: data => request({
    url: '/api/services/app/OrganizationUnit/GetOrganizationUnits',
    method: 'get',
    params: data
  }),
  getApplyList: data => request({
    url: '/api/services/app/OrganizationUnit/GetApplyList',
    method: 'get',
    params: data
  }),
  getOrganizationUnitUsers: data => request({
    url: '/api/services/app/OrganizationUnit/GetOrganizationUnitUsers',
    method: 'get',
    params: data
  }),
  updateOrganizationUnit: data => request({
    url: '/api/services/app/OrganizationUnit/UpdateOrganizationUnit',
    method: 'put',
    data: data
  }),
  createOrganizationUnit: data => request({
    url: '/api/services/app/OrganizationUnit/CreateOrganizationUnit',
    method: 'post',
    data: data
  }),
  addUsersToOrganizationUnit: data => request({
    url: '/api/services/app/OrganizationUnit/AddUsersToOrganizationUnit',
    method: 'post',
    data: {
      userIds: data.userIds,
      organizationUnitId: data.organizationUnitId
    }
  }),
  delete: data => {
    return request({
      url: '/api/services/app/OrganizationUnit/DeleteOrganizationUnit',
      method: 'delete',
      params: data
    })
  },
  removeUsersFromOU: data => {
    return request({
      url: '/api/services/app/OrganizationUnit/RemoveUsersFromOrganizationUnit',
      method: 'post',
      data: data
    })
  },
  // TODO -- START 机构申请 同意/拒绝等待接口
  approve: data => {
    return request({
      url: '/api/services/app/OrganizationUnit/ApproveApply',
      method: 'post',
      data: data
    })
  },
  refuse: data => {
    return request({
      url: '/api/services/app/OrganizationUnit/RefuseApply',
      method: 'post',
      data: data
    })
  },
  // TODO --- END
  //
  getOrganizationUnitCommentList: data => request({
    url: '/api/services/app/OrgComment/GetAll',
    method: 'get',
    params: data
  }),
  hideComment: data => request({
    url: '/api/services/app/OrgComment/HideComment',
    method: 'post',
    data: data
  }),
  showComment: data => request({
    url: '/api/services/app/OrgComment/ShowComment',
    method: 'post',
    data: data
  }),
  deleteComment: data => {
    return request({
      url: '/api/services/app/OrgComment/Delete',
      method: 'delete',
      params: data
    })
  },
}
