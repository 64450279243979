import {
  RouteConfig
} from 'vue-router'
import Layout from '@/layout/index.vue'

const notifyRouter: RouteConfig[] = [
  {
    path: '/notify',
    component: Layout,
    name: 'cms',
    redirect: '/notify/template',
    meta: {
      title: '通知模板'
    },
    children: [
      {
        path: 'list',
        name: 'notify-list',
        component: () => import(/* webpackChunkName: "notify" */'@/views/notify/template/list.vue'),
        meta: {
          title: '通知模板列表'
        }
      },
      {
        path: 'create',
        name: 'notifyCreate',
        component: () => import(/* webpackChunkName: "notify" */'@/views/notify/template/edit.vue'),
        meta: {
          title: '创建通知模板'
        }
      },
      {
        path: 'edit/:id',
        name: 'notifyEdit',
        component: () => import(/* webpackChunkName: "notify" */'@/views/notify/template/edit.vue'),
        meta: {
          title: '修改通知模板'
        }
      },
      {
        path: 'detail/:id',
        name: 'notifyDetail',
        component: () => import(/* webpackChunkName: "notify" */'@/views/notify/template/detail.vue'),
        meta: {
          title: '通知模板详情'
        }
      }
    ]
  }
]


export default notifyRouter;
