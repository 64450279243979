import request from '@/utils/request'

import {
  serviceOptions,
  AccountService,
  UserService,
  OrganizationUnitService,
  // AuditLogService,
  CmsContentService,
  CmsCategoryService,
  CmsAnchorService,
  SwiperService,
  CmsFriendLinkService,

  SessionService,
  RoleService,
  AuditFlowService,
  AuditNodePreDefineService,
  DepartmentService,
  AttachmentService,
  PermissionDefinitionService,

  NotifyTemplateService,
  DataDictionaryService,

  PartnerInviteService,

  EnumService,
  AlbumService,
  CapitalIncomeRecordFromOtherService,
  CapitalIncomeRecordFromDonationService,
  CapitalExpenseRecordAppserviceService,
  InternalMonthlyPlanService,
  InternalMonthlySummarizeService,
  InternalPersonalYearEndSummaryService,
  ExternalProjectIntentionService,
  ExternalMonthlyPlanService,
  ExternalMonthlySummarizeService,
  ProjectInviteService,
  ProjectService,
  ProjectSeriesService,
  ExternalDueDiligenceRecordService,
  ExternalProjectProcessMonitoringService,
  ExternalProjectProgressReportService,
  ExternalProjectSummaryReportService,
  ExternalProjectService,
  ExternalProjectCloseReportService,
  NotifyRecordService,
  ProvinceService,
  CityService,
  AreaService,
  TownService,
  CustomFormService,
  InternalProjectSummaryReportsService,
  InternalProjectService,
  PartnerCapitalApplyService,
  BeneficiaryService,
  ProjectBeneficiaryMappingService,
  BeneficiaryServiceRecordService,
  BankReconciliationService,
  InternalProjectPhaseService,
  ExternalProjectPhaseService,
  InternalProjectReportService,
  ExternalProjectReportService,
  ExternalProjectChangeApplyService,
  FormCacheService,
  PartnerCapitalApplySettlementService,
  MatterAuditFormService,
  PrivateCarPublicSubsidyFormService,
  VehicleUsageApplicationFormService,
  TravelApprovalFormService,
  ItemBorrowingFormService,
  InventoryInFormService,
  InventoryOutFormService, TokenAuthService
} from './appService'

import { Attachment } from './attachment';


serviceOptions.axios = request;

//const basrUrl = process.env.VUE_APP_BASE_API;

const auditFlow = AuditFlowService;
const auditNodePreDefine = AuditNodePreDefineService;
const account = AccountService;
const album = AlbumService;
const cmsContent = CmsContentService;
const cmsCategory = CmsCategoryService;
const cmsAnchor = CmsAnchorService;
const cmsFriendLink = CmsFriendLinkService;
const swiper = SwiperService;
const department = DepartmentService;
const organizationUnit = OrganizationUnitService;
const role = RoleService;
const session = SessionService;
const user = UserService;
const attachment = Attachment;
const attachmentService = AttachmentService;
const permissionDefinition = PermissionDefinitionService;

const partnerInvite = PartnerInviteService;
const notifyTemplate = NotifyTemplateService;
const dataDictionary = DataDictionaryService;
const capitalIncomeRecordFromOther = CapitalIncomeRecordFromOtherService;
const capitalIncomeRecordFromDonation = CapitalIncomeRecordFromDonationService;
const capitalExpenseRecord = CapitalExpenseRecordAppserviceService;
const internalMonthlyPlan = InternalMonthlyPlanService;
const internalMonthlySummarize = InternalMonthlySummarizeService;
const internalPersonalYearEndSummary = InternalPersonalYearEndSummaryService;
const enumService = EnumService;
const externalProjectIntention = ExternalProjectIntentionService;
const externalMonthlyPlanService = ExternalMonthlyPlanService;
const externalMonthlySummarize = ExternalMonthlySummarizeService;
const projectInvite = ProjectInviteService;
const projectSeries = ProjectSeriesService;
const project = ProjectService;
const externalDueDiligenceRecord = ExternalDueDiligenceRecordService;
const externalProjectProcessMonitoringService = ExternalProjectProcessMonitoringService;
const externalProjectProcessReport = ExternalProjectProgressReportService;
const externalProjectSummaryReport = ExternalProjectSummaryReportService;
const notifyRecord = NotifyRecordService;
//
const province = ProvinceService;
const city = CityService;
const area = AreaService;
const town = TownService;
const externalProject = ExternalProjectService;
const externalProjectCloseReport = ExternalProjectCloseReportService;

const customFormService = CustomFormService;
const internalProjectSummaryReports = InternalProjectSummaryReportsService;
const internalProject = InternalProjectService;
const partnerCapitalApply = PartnerCapitalApplyService;
const partnerCapitalApplySettlement = PartnerCapitalApplySettlementService;
const beneficiary = BeneficiaryService;
const projectBeneficiaryMapping = ProjectBeneficiaryMappingService;
const beneficiaryServiceRecord = BeneficiaryServiceRecordService;
const bankReconciliation = BankReconciliationService;
const guid = '00000000-0000-0000-0000-000000000000';

const internalProjectPhase = InternalProjectPhaseService;
const externalProjectPhase = ExternalProjectPhaseService;
const internalProjectReport = InternalProjectReportService;
const externalProjectReport = ExternalProjectReportService;
const externalProjectChangeApply = ExternalProjectChangeApplyService;
const formCache = FormCacheService;

const matterAuditForm=MatterAuditFormService;
const privateCarPublicSubsidyForm=PrivateCarPublicSubsidyFormService;
const vehicleUsageApplication=VehicleUsageApplicationFormService;
const travelApproval=TravelApprovalFormService;
const itemBorrowing=ItemBorrowingFormService;
const inventoryIn=InventoryInFormService;
const inventoryOut=InventoryOutFormService;
const tokenAuth=TokenAuthService;

export default {
  auditFlow,
  album,
  auditNodePreDefine,
  account,
  cmsContent, cmsCategory, cmsAnchor, cmsFriendLink, swiper,
  organizationUnit, department,
  role,
  session,
  user,
  attachment,
  attachmentService,
  permissionDefinition,
  notifyTemplate,
  partnerInvite,
  enumService,
  guid,
  dataDictionary,
  capitalIncomeRecordFromOther,
  capitalIncomeRecordFromDonation,
  capitalExpenseRecord,
  internalMonthlyPlan,
  internalMonthlySummarize,
  internalPersonalYearEndSummary,
  externalProjectIntention,
  projectInvite,
  projectSeries,
  project,
  externalDueDiligenceRecord,
  externalProjectProcessMonitoringService,
  externalProjectProcessReport,
  externalMonthlyPlanService,
  externalMonthlySummarize,
  notifyRecord,
  externalProjectSummaryReport,
  province, city, area, town,
  externalProject,
  externalProjectCloseReport,
  internalProjectSummaryReports,
  internalProject,
  customFormService,
  partnerCapitalApply,
  partnerCapitalApplySettlement,
  beneficiary,
  projectBeneficiaryMapping,
  beneficiaryServiceRecord,
  bankReconciliation,
  internalProjectPhase,
  externalProjectPhase,
  internalProjectReport,
  externalProjectReport,
  externalProjectChangeApply,
  formCache,
  matterAuditForm,
  privateCarPublicSubsidyForm,
  vehicleUsageApplication,
  travelApproval,
  itemBorrowing,
  inventoryIn,
  inventoryOut,
  tokenAuth
}
