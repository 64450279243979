import {
  RouteConfig
} from 'vue-router'
import Layout from '@/layout/index.vue'

const auditRouter: RouteConfig[] = [
  {
    path: '/audit',
    name: 'audit',
    redirect: '/audit/flowDefinition',
    component: Layout,
    meta: {
      title: '组织管理'
    },
    children: [
      {
        path: 'flowDefinition/management',
        name:'auditFlowDefinitionManagement',
        component: () => import(/* webpackChunkName: "audit" */ '@/views/audits/auditFlowList.vue'),
        meta: {
          title: '审核流程定义'
        },

      },
      {
        path:'flowDefinition/edit/:id',
        name:'auditFlowDefinitionEdit',
        component: () => import(/* webpackChunkName: "audit" */ '@/views/audits/edit-auditFlow.vue'),
        meta: {
          title: '审核流程编辑'
        }
      },
      {
        path:'flowDefinition/create',
        name:'auditFlowDefinitionCreate',
        component: () => import(/* webpackChunkName: "audit" */ '@/views/audits/edit-auditFlow.vue'),
        meta: {
          title: '审核流程编辑'
        }
      },

      {
        path: 'preDefinition',
        name: 'preDefinition',
        component: () => import(/* webpackChunkName: "audit" */ '@/views/audits/auditNodePreDefineList.vue'),
        meta: {
          title: '审核节点预定义'
        }
      },

      {
        path: 'auditFlowDetail/:hostType/:hostId/:auditFlowId',
        name: 'auditFlowDetail',
        component: () => import(/* webpackChunkName: "audit" */ '@/views/audits/auditFlowDetail.vue'),
        meta: {
          title: '审核信息详情'
        }
      },
      {
        path:'myAudit',
        name:'myAudit',
        component: () => import(/* webpackChunkName: "audit" */ '@/views/myAudit/index.vue'),
        meta: {
          title: '我的审核列表'
        }
      }
    ]
  }
]


export default auditRouter;
