import { UserManager, WebStorageStateStore, User } from "oidc-client";

const AUTH0_DOMAIN = process.env.VUE_APP_BASE_API;
export default class AuthService {
    public userManager: UserManager;
    constructor() {
        const settings: any = {
            authority: AUTH0_DOMAIN,
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            client_id: 'client_id_js',
            redirect_uri: window.location.origin + '/callback.html',
            response_type: 'id_token token',
            scope: 'openid profile roles phone default-api',
            post_logout_redirect_uri: window.location.origin + '/index.html',

            silent_redirect_uri: window.location.origin + '/silent-renew.html',
            accessTokenExpiringNotificationTime: 20,
            automaticSilentRenew: false,
            filterProtocolClaims: true,
            loadUserInfo: true,
            checkSessionInterval: 200000
        };
        console.log("settings", settings);
        this.userManager = new UserManager(settings)
    }

    public getUser(): Promise<User | null> {
        return this.userManager!.getUser();
    }

    public login(): void {
        this.userManager!.clearStaleState().then(res => {
            console.log("clearStaleState ok")
            this.userManager!.signinRedirect();

        });
    }

    public logout(): void {
        this.userManager!.clearStaleState().then(res => {
            console.log("clearStaleState ok")
            this.userManager!.signoutRedirect();
        });
    }

    public signinSilent(): Promise<User | null> {
        return this.userManager!.signinSilent();
    }
}