import {
  RouteConfig
} from 'vue-router'
import Layout from '@/layout/index.vue'

const partnerInviteRouter: RouteConfig[] = [
  {
    path: '/partnerInvite',
    name: 'partnerInvite',
    redirect: '/partnerInvite',
    component: Layout,
    meta: {
      title: '合作方管理'
    },
    children: [
      {
        path: 'index',
        name:'index',
        component: () => import(/* webpackChunkName: "partnerInvite" */ '@/views/partnerInvite/index.vue'),
        meta: {
          title: '合作方邀约列表'
        },

      },
      {
        path: 'create',
        name: 'partnerInviteCreate',
        component: () => import(/* webpackChunkName: "contentList" */ '@/views/partnerInvite/edit.vue'),
        meta: {
          title: '合作方邀约新增'
        }
      },
      {
        path:'edit/:id',
        name:'partnerInviteEdit',
        component: () => import(/* webpackChunkName: "partnerInvite" */ '@/views/partnerInvite/edit.vue'),
        meta: {
          title: '合作方邀约编辑'
        }
      },
      {
        path: 'import/result/:reportName',
        name: 'uploadTemplateDetail',
        component: () => import('@/components/UploadTemplate/result.vue'),
        meta: {
          title: '模板导入结果'
        }
      },
      {
        path: 'customForm',
        name:'partnerCustomForm',
        component: () => import(/* webpackChunkName: "partnerInvite" */ '@/views/partnerCustomForm/index.vue'),
        meta: {
          title: '合作方表单维护'
        },
      },
    ]
  }
]


export default partnerInviteRouter;
